import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { ordersSelector } from '../../../state/features/selectors';
import { filter, isEmpty } from 'lodash';
import InnerContentLoader from '../../../components/InnerContentLoader';
import { errorNotify, successNotify } from '../../../helpers/notify';
import GetOrder from './GetOrder';
import DeliverOrder from './DeliverOrder';

const OrderDelivery = ({ order: widgetOrder }) => {
    // ############## Comp state #############
    const [order, setOrder] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');

    // ############## Redux state #############
    const { runningOrders } = useSelector(ordersSelector);

    // ############## FUNC ####################
    // => Get order
    const getOrder = (payload) => {
        let order_id = payload.order_id;
        setIsLoading(true);
        let orderToDeliver = filter(
            runningOrders,
            ({ order_number }) => order_number === order_id,
        );
        setOrderNumber(order_id);
        setOrder(orderToDeliver[0]);
        setTimeout(() => {
            if (isEmpty(orderToDeliver)) {
                errorNotify(
                    'Order not found. Please make sure you provide correct Order number.',
                );
                setIsError(true);
            } else {
                successNotify('Order ready for delivery.');
                setIsError(false);
            }
            setIsLoading(false);
        }, 5000);
    };

    // ############## useEffect #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setOrder(widgetOrder);
        }
        return () => {
            isSubscribed = false;
        };
    }, [widgetOrder]);

    return (
        <>
            <Box sx={{ height: { md: 450, xs: 480 } }}>
                {isLoading ? (
                    <>
                        <Box>
                            <InnerContentLoader height={{ md: 450, xs: 450 }} />
                        </Box>
                    </>
                ) : (
                    <>
                        <Grid container justifyContent={'center'}>
                            {isEmpty(order) ? (
                                <>
                                    <Grid item sm={12} xs={12}>
                                        <GetOrder
                                            getOrder={getOrder}
                                            isError={isError}
                                            orderNumber={orderNumber}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <DeliverOrder order={order} />
                                </>
                            )}
                        </Grid>
                    </>
                )}
            </Box>
        </>
    );
};

export default OrderDelivery;
