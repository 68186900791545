import * as React from "react";
import Box from "@mui/material/Box";
import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import BottomBar from "../../components/BottomBar";
import { useDispatch } from "react-redux";
import { setOrders } from "../../state/features/orders/orderSlice";
import { useGetAllOrdersQuery } from "../../api/ordersAPI";
import DataFetchError from "../errors/DataFetchError";
import NoNetworkError from "../errors/NoNetworkError";
import useNetwork from "../../hooks/useNetwork";
import FullAppLoader from "../../components/FullAppLoader";
import useAuth from "../../hooks/useAuth";
import HotToastfy from "../../components/HotToastfy copy";
import { getRTKErrorMessage } from "../../helpers/RTKHelpers";
import MobQuickDelivery from "../../components/MobQuickDelivery";

const AppLayout = ({ children }) => {
    // ############## CONST ###################
    const dispatch = useDispatch();
    const checkNetwork = useNetwork();
    const auth = useAuth();
    const user_id = auth.authenticatedUser().user._id;

    // ############## COMP State ###################
    const [mobileOpen, setMobileOpen] = React.useState(false);

    // ############## FUNC ###################
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // ############## RTK ###################
    // => Fetch orders
    const {
        isLoading: ordersLoding,
        isSuccess: ordersSuccess,
        isError: ordersError,
        error,
        data: orders,
    } = useGetAllOrdersQuery(user_id);

    // ############## UseEffect ###################
    // => Dispatch main data
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (ordersSuccess) {
                dispatch(setOrders(orders));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [orders, ordersSuccess, dispatch]);

    return (
        <>
            <HotToastfy />
            {ordersLoding ? (
                <>
                    <FullAppLoader />
                </>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        position: "absolute",
                        top: 0,
                        bottom: "100%",
                        right: 0,
                        left: 0,
                    }}
                >
                    {/* ############### topbar ############### */}
                    <TopBar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                        desktopOpen={true}
                    />

                    {/* ############### sidebar ###############*/}
                    <SideBar mobileOpen={mobileOpen} desktopOpen={true} />

                    {/* ############### content ###############*/}
                    <Box component="main" flexGrow={1}>
                        {!checkNetwork ? (
                            <>
                                <NoNetworkError />
                            </>
                        ) : (
                            <>
                                {ordersError &&
                                getRTKErrorMessage(error) !==
                                    "No Running Orders Found" ? (
                                    <DataFetchError />
                                ) : (
                                    <>
                                        {children}

                                        {/* ############### BottomBar############# */}
                                        <Box
                                            sx={{
                                                display: {
                                                    md: "none",
                                                    xs: "block",
                                                },
                                            }}
                                        >
                                            <BottomBar />
                                            <MobQuickDelivery />
                                        </Box>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default AppLayout;
