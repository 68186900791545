import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/colors';
import OrderWidget2 from '../widgets/OrderWidget2';

const columns = [{ id: 'order' }];

function createData(order) {
    return { order };
}

const orderRow = (order, index) => createData(<OrderWidget2 order={order} />);

// !################ MAIN FUNC ######################
const MainOrderTable2 = ({ orders, height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // ############# oreders ###############
    const storeOrders = orders.map((order, index) => orderRow(order, index));

    return (
        <Paper
            sx={{
                width: '100%',
                overflow: 'hidden',
                boxShadow: 'none',
                border: 'none',
            }}
        >
            <TableContainer
                sx={{
                    height: height ? height : 485,
                    background: colors.bgColor3,
                    p: 0,
                }}
            >
                <Table stickyHeader>
                    <TableHead sx={{ overflow: 'hidden' }}>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                        bgcolor: colors.bgColor1,
                                        opacity: 0.8,
                                        p: 0,
                                    }}
                                    className="head-font"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflow: 'hidden' }}>
                        {storeOrders
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {columns.map((column, index) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={index}
                                                    sx={{ p: 0 }}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={orders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    'display': 'flex',
                    'justifyContent': 'flex-start',
                    'bgcolor': colors.bgColor1,
                    '& .MuiTablePagination-selectLabel': {
                        display: 'none',
                    },
                }}
            />
        </Paper>
    );
};

export default MainOrderTable2;
