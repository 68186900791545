import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Avatar, Box, Stack, styled, useTheme } from "@mui/material";
import logo from "../assets/media/images/logo1.png";
import { colors } from "../assets/utils/colors";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
// import { authSelector } from "../state/features/selectors";
// import { useSelector } from "react-redux";
import useColor from "../hooks/useColor";
import { Link } from "react-router-dom";

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    background: colors.bgColor2,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: colors.primary,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// ################# TOPBAR RIGHT ###############
const TopbarLeft = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .drawer-toggle": {
        padding: 1,
        backgroundImage: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.primary})`,
        color: colors.bgColor3,
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: colors.primary,
            color: colors.bgColor3,
            fontWeight: "bold",
        },
    },
});

// !###################### MAIN FUNC ############################
const TopBar = ({ mobileOpen, desktopOpen, handleDrawerToggle }) => {
    const theme = useTheme();
    const colors = useColor();
    // ######### Redux states #############
    // const { user } = useSelector(authSelector);
    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: {
                        xs: "100%",
                        sm: desktopOpen
                            ? `calc(100% - ${drawerWidth}px)`
                            : `95.8%`,
                    },
                    borderBottom: `1px solid ${colors.bgColor1}`,
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        bgcolor: colors.bgColor3,
                    }}
                >
                    {/* ################### LEFT ################# */}
                    <TopbarLeft>
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            {mobileOpen ? (
                                <IconButton
                                    className="drawer-toggle"
                                    onClick={handleDrawerToggle}
                                >
                                    {theme.direction === "rtl" ? (
                                        <ChevronRight />
                                    ) : (
                                        <ChevronLeft />
                                    )}
                                </IconButton>
                            ) : (
                                <IconButton
                                    className="drawer-toggle"
                                    onClick={handleDrawerToggle}
                                >
                                    {theme.direction === "rtl" ? (
                                        <ChevronRight />
                                    ) : (
                                        <ChevronRight />
                                    )}
                                </IconButton>
                            )}
                        </Box>

                        <Typography
                            sx={{
                                color: colors.primary,
                                fontWeight: "bold",
                            }}
                        >
                            Agiza Africa delivery
                        </Typography>
                    </TopbarLeft>

                    {/* ################### RIGHT ################# */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Box>
                            <Link to={"/profile"}>
                                <Avatar
                                    alt="DP"
                                    src={logo}
                                    sx={{
                                        bgcolor: colors.bgColor2,
                                        p: 0.4,
                                        border: `2px solid ${colors.primary}`,
                                    }}
                                />
                            </Link>
                        </Box>
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
