import React from "react";
import { Box, Grid, styled } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import CustomCard2 from "../cards/CustomCard2";
// import RunningOrderCarousel from '../carousel/RunningOrderCarousel';
// import CountBadge from '../CountBadge';
// import { useSelector } from "react-redux";
// import { ordersSelector } from "../../state/features/selectors";
import MonthlyStatsWidget from "../widgets/MonthlyStatsWidget";
import OverallStatsWidget from "../widgets/OverallStatsWidget";
import DeliveryAppBtn from "../DeliveryAppBtn";

// ################ OrdersBar ###############
const DashSideBar = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .section-head": {
        fontSize: 16,
        fontWeight: "bold",
        position: "relative",
        marginBottom: 15,
        color: colors.primary,
        "&::before": {
            content: "''",
            height: 2.5,
            width: 50,
            position: "absolute",
            bottom: -3,
            borderRadius: 110,
            background: colors.bgColor1,
        },
    },
}));

const Dashsidebar = () => {
    // ############## Redux state #############
    // const { runningOrders } = useSelector(ordersSelector);

    return (
        <>
            <DashSideBar sx={{ pb: { md: 0, xs: 10 } }}>
                {/* <Grid container spacing={2}> */}
                {/* <Grid item sm={12} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography className="head-font section-head">
                                Running orders
                            </Typography>
                            <Box
                                sx={{
                                    mt: -2,
                                }}
                            >
                                <CountBadge total={size(runningOrders)} item={'orders'} />
                            </Box>
                        </Box>
                        <CustomCard2 height={{ md: 298, xs: '100%' }}>
                            <RunningOrderCarousel orders={runningOrders} />
                        </CustomCard2>
                    </Grid> */}
                <Grid item sm={12} xs={12}>
                    <CustomCard2 height={91} title={"Monthly order stats"}>
                        <MonthlyStatsWidget />
                    </CustomCard2>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <CustomCard2 height={91} title={"Overall order stats"}>
                        <OverallStatsWidget />
                    </CustomCard2>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <DeliveryAppBtn />
                </Grid>
                {/* </Grid> */}
            </DashSideBar>
        </>
    );
};

export default Dashsidebar;
