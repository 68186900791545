import React from "react";
import { Box, Grid, styled } from "@mui/material";
import InnerPageTopbar from "../../components/InnerPageTopbar";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const PageLayout = ({ children, sidebar, pageHead }) => {
    return (
        <>
            <Box>
                <Grid container columnSpacing={2} paddingX={2}>
                    <Grid
                        item
                        sm={9}
                        xs={12}
                        sx={{
                            height: "100vh",
                            display: "flex",
                            flexDirection: "column",
                            py: 1,
                        }}
                    >
                        <DrawerHeader />
                        {/* ############# Page title ############# */}
                        {pageHead ? (
                            <>
                                <InnerPageTopbar title={pageHead} />
                            </>
                        ) : (
                            <></>
                        )}
                        {children}
                    </Grid>
                    <Grid
                        item
                        sm={3}
                        xs={12}
                        sx={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            py: 1,
                            height: { md: "100vh", xs: "100%" },
                        }}
                    >
                        <DrawerHeader />
                        {sidebar}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default PageLayout;
