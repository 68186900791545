import React from "react";
import { Box, Grid } from "@mui/material";
import { colors } from "../assets/utils/colors";
import DashOrderOverviewWidget from "../components/widgets/DashOrderOverviewWidget";
import CustomCardOne from "../components/cards/CustomCardOne";
import Dashsidebar from "../components/sidebars/Dashsidebar";
import QuickDeliveryBtn from "../components/QuickDeliveryBtn";
import { useSelector } from "react-redux";
import { ordersSelector } from "../state/features/selectors";
import NoContent from "../components/NoContent";
import MainOrderTable from "../components/tables/MainOrderTable";
import { isEmpty, size } from "lodash";
import DashLayout from "./layouts/DashLayout";

const Dashboard = () => {
    // ############## Redux state ################
    const { orders, runningOrders, completedOrders } =
        useSelector(ordersSelector);

    return (
        <>
            <DashLayout sidebar={<Dashsidebar />}>
                <Box sx={{ p: 1 }}>
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            <Grid
                                container
                                spacing={3}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Grid item sm={4.5} xs={12}>
                                    <DashOrderOverviewWidget
                                        bdColor={colors.yellow}
                                        title={"Running orders"}
                                        total={size(runningOrders)}
                                        type="running"
                                    />
                                </Grid>
                                <Grid item sm={4.5} xs={8}>
                                    <DashOrderOverviewWidget
                                        bdColor={colors.green}
                                        title={"Completed orders"}
                                        total={size(completedOrders)}
                                        type="completed"
                                    />
                                </Grid>
                                <Grid item sm={3} xs={4}>
                                    <QuickDeliveryBtn />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        overflow: "auto",
                        position: "relative",
                        mt: 2,
                    }}
                >
                    <Grid container>
                        <Grid
                            item
                            sm={12}
                            sx={{ display: { md: "block", xs: "none" } }}
                        >
                            <CustomCardOne title={"Latest orders"}>
                                <Grid container>
                                    <Grid item sm={12} xs={12}>
                                        {isEmpty(orders) ? (
                                            <>
                                                <NoContent
                                                    message={
                                                        "No order available"
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <MainOrderTable
                                                orders={orders}
                                                height={388}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </CustomCardOne>
                        </Grid>
                    </Grid>
                </Box>
            </DashLayout>
        </>
    );
};

export default Dashboard;
