import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Avatar, Grid } from "@mui/material";
import { Box } from "@mui/system";
import logo2 from "../assets/media/images/logo2.png";
import logo1 from "../assets/media/images/logo1.png";
import { colors } from "../assets/utils/colors";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLoginMutation } from "../api/authAPI";
import { useDispatch } from "react-redux";
import useAuth from "../hooks/useAuth";
import { isEmpty } from "lodash";
import { setUserProfile } from "../state/features/auth/authSlice";
import { errorNotify } from "../helpers/notify";
import HotToastfy from "../components/HotToastfy";
import CustomSubmitButton from "../components/forms/CustomSubmitButton";
import CustomTextField from "../components/forms/CustomTextField";
import useNetwork from "../hooks/useNetwork";
import NoNetworkError from "./errors/NoNetworkError";
import WelcomeTopbar from "../components/WelcomeTopbar";
import DeliveryAppBtn from "../components/DeliveryAppBtn";

// ################### MUI CONSTANT ################
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

// ################### LoginModal ################
const LoginModal = styled(Grid)(({ theme }) => ({
    "& .left-content": {
        height: "60vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: colors.bgColor3,
        zIndex: 50,
        "& .fade-logo": {
            width: 250,
            height: 350,
            zIndex: 10,
            opacity: 0.1,
        },
        "& .sign-head": {
            fontSize: 20,
            fontWeight: "bold",
        },
    },
    "& .right-content": {
        height: "60vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 150,
        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
        "& .input-sign-head": {
            zIndex: 100,
            fontWeight: "bold",
        },
        "& .close-btn": {
            width: 35,
            height: 35,
            background: colors.bgColor2,
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
        },
        "& .input-card": {
            width: 350,
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        "& .logo-con": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            "& .logo": {
                width: 160,
                height: 66,
            },
        },
    },
    [theme.breakpoints.down("md")]: {
        "& .sign-head": {
            display: "none",
        },
        "& .left-content": {
            position: "absolute",
            width: "100%",
        },
        "& .right-content": {
            height: "100vh",
            justifyContent: "flex-end",
        },
        "& .input-card": {
            width: "90% !important",
            height: "50vh !important",
            justifyContent: "space-around !important",
        },
        "& .logo-con": {
            display: "none",
        },
    },
}));

// ########## MUI Custom ############
const PageBgImageCon = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

// ################### Validation ###############
const loginValidationSchema = Yup.object({
    phone_number: Yup.string().required("Agiza id is a required field"),
    password: Yup.string().required(),
});

// !################## MAIN FUNC ####################
export default function Welcome({ open, handleClose }) {
    // ################### CONST #####################
    const auth = useAuth();
    const dispatch = useDispatch();
    const checkNetwork = useNetwork();

    // ################### RTK #######################
    const [login, { isLoading }] = useLoginMutation();

    // => Handle login
    const handleLogin = async (payload) => {
        let res = await login(payload);
        if (!isEmpty(res.data)) {
            dispatch(setUserProfile(res.data));
            auth.saveAuthenticatedUser(res.data);
        } else if (res.error) {
            return errorNotify(
                res.error.data.message
                    ? res.error.data.message
                    : "Temporary server error. Please try again letter."
            );
        } else {
            let message = res.error.data.message;
            return errorNotify(message);
        }
    };

    return (
        <>
            <HotToastfy />
            {!checkNetwork ? (
                <>
                    <PageBgImageCon>
                        <img
                            src={logo2}
                            alt=""
                            style={{ marginLeft: 100, opacity: 0.04 }}
                        />
                    </PageBgImageCon>
                    <NoNetworkError />
                </>
            ) : (
                <>
                    {/* ################ TOPBAR ############### */}
                    <WelcomeTopbar />
                    <Box
                        sx={{
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        <LoginModal
                            container
                            justifyContent={"center"}
                        >
                            <Grid item sm={8} sx={{ overflow: "hidden" }}>
                                <Grid container>
                                    {/* ################### LEFT ################## */}
                                    <Grid item sm={6} className="left-content">
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Typography className="head-font sign-head">
                                                Sign In
                                            </Typography>
                                            <Typography
                                                className="head-font sign-head"
                                                sx={{ mt: 1 }}
                                            >
                                                Agiza Africa
                                            </Typography>
                                            <Typography
                                                className="head-font sign-head"
                                                sx={{
                                                    my: 3,
                                                    textTransform: "uppercase",
                                                    color: colors.primary,
                                                }}
                                            >
                                                Delivery system
                                            </Typography>
                                            <Box>
                                                <DeliveryAppBtn />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Avatar
                                                alt="Agiza logo"
                                                src={logo2}
                                                className="fade-logo"
                                                variant="square"
                                            />
                                        </Box>
                                    </Grid>

                                    {/* ################### RIGHT ################## */}
                                    <Grid item sm={6} className="right-content">
                                        <Formik
                                            initialValues={{
                                                phone_number: "",
                                                password: "",
                                                // phone_number: '0743567890',
                                                // password: 'buibui123',
                                            }}
                                            validationSchema={
                                                loginValidationSchema
                                            }
                                            onSubmit={(payload) => {
                                                handleLogin(payload);
                                            }}
                                        >
                                            {(formik) => (
                                                <Box className="input-card">
                                                    <Box
                                                        className="logo-con"
                                                        sx={{
                                                            display: {
                                                                md: "block",
                                                                sm: "block",
                                                                xs: "none",
                                                            },
                                                        }}
                                                    >
                                                        <Avatar
                                                            src={logo1}
                                                            alt="Agiza logo"
                                                            className="logo"
                                                        />
                                                    </Box>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography className="input-sign-head">
                                                            Provide your
                                                            credential
                                                        </Typography>
                                                        <Box>
                                                            <CustomTextField
                                                                type="text"
                                                                name="phone_number"
                                                                label={
                                                                    "Type your phone number"
                                                                }
                                                            />
                                                            <CustomTextField
                                                                type="password"
                                                                name="password"
                                                                label={
                                                                    "Type your Password"
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <CustomSubmitButton
                                                            loading={isLoading}
                                                            onClick={
                                                                formik.handleSubmit
                                                            }
                                                        >
                                                            Sign in
                                                        </CustomSubmitButton>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Formik>
                                        <Box
                                            sx={{
                                                display: {
                                                    md: "none",
                                                    sm: "none",
                                                    xs: "block",
                                                },
                                            }}
                                        >
                                            <DeliveryAppBtn />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "fixed",
                                                bottom: 0,
                                                top: 0,
                                                left: 0,
                                                display: {
                                                    md: "flex",
                                                    xs: "none",
                                                },
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={logo2}
                                                alt=""
                                                style={{
                                                    width: 120,
                                                    opacity: 0.2,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "fixed",
                                                bottom: 0,
                                                top: 0,
                                                right: 0,
                                                display: {
                                                    md: "flex",
                                                    xs: "none",
                                                },
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={logo2}
                                                alt=""
                                                style={{
                                                    width: 120,
                                                    opacity: 0.2,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                position: "fixed",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img
                                                src={logo2}
                                                alt=""
                                                style={{
                                                    width: 120,
                                                    opacity: 0.2,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </LoginModal>
                    </Box>
                </>
            )}
        </>
    );
}
