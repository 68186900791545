import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

// => initial state
const initialState = {
    orders: [],
    runningOrders: [],
    completedOrders: [],
    orderDetails: {},
};

const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setOrders: (state, action) => {
            let allOrders = action.payload.data;
            let running = allOrders.running_orders;
            let completed = allOrders.order_completed;
            let orders = running;
            if (!isEmpty(completed)) {
                orders = [...running, ...completed];
            }
            state.orders = orders;
            state.runningOrders = running;
            state.completedOrders = completed;
        },
        setOrderDetails: (state, action) => {
            let orderDetails = action.payload;
            state.orderDetails = orderDetails;
        },
    },
});

export const { setOrders, setOrderDetails } = orderSlice.actions;
export default orderSlice.reducer;
