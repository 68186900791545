import React from "react";

// =============== MUI import ==============
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Container, Typography, styled } from "@mui/material";

// =============== Asset import ==============
import { radius } from "../assets/utils/radius";
import { colors } from "../assets/utils/colors";
import TimeWidget from "./widgets/TimeWidget";
import { useDate } from "../hooks/useDate";

// ============= Styled MUI ==================
const TopBar = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    position: "absolute",
    // 'backgroundImage': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
    "& .toolbar": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundImage: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
    },
    "& .logo": {
        width: 150,
        height: 56,
    },
    "& .login-btn": {
        textTransform: "capitalize",
        borderRadius: radius.btnRadius,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
        ".login-btn": {
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 15,
            paddingRight: 15,
            boxShadow: "none",
        },
        ".toolbar-con": {
            padding: 0,
        },
        ".toolbar": {
            paddingTop: 10,
        },
    },
    background: "transparent",
}));

const WelcomeTopbar = () => {
    const date = useDate();
    return (
        <>
            <TopBar position="fixed">
                <Container className="toolbar-con" maxWidth="xl">
                    <Toolbar className="toolbar">
                        {/* ################### LEFT ##################### */}
                        <Box>
                            {/* <Avatar
                                alt="Agiza logo"
                                src={logo}
                                className="logo"
                            /> */}
                        </Box>

                        {/* ################### CENTER ##################### */}
                        <Box sx={{ mt: -1, py: 2.5 }}>
                            <TimeWidget />
                            <Typography
                                sx={{
                                    py: 1,
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    textAlign: "center",
                                }}
                            >
                                {date.wish_1}
                            </Typography>
                        </Box>

                        {/* ################### RIGHT ##################### */}
                        <Box>
                            {/* <Button
                                variant="contained"
                                className="login-btn app-btn"
                                onClick={() => {
                                    handleClickOpen();
                                }}
                            >
                                Go to dashboard
                            </Button> */}
                        </Box>
                    </Toolbar>
                </Container>
            </TopBar>
        </>
    );
};

export default WelcomeTopbar;
