import React from "react";
import { Box, Grid, styled } from "@mui/material";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const DashLayout = ({ children, sidebar }) => {
    return (
        <>
            <Box>
                <Grid container columnSpacing={2} paddingX={2}>
                    <Grid
                        item
                        sm={9}
                        xs={12}
                        sx={{
                            height: "100vh",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <DrawerHeader />
                        {children}
                    </Grid>
                    <Grid
                        item
                        sm={3}
                        xs={12}
                        sx={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            height: { md: "100vh", xs: "100%" },
                            py: 1,
                        }}
                    >
                        <DrawerHeader />
                        {sidebar}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DashLayout;
