import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    user: [],
    authToken: '',
    isAuthenticated: false,
    authorizationType: '',
};

// !########## SLICE #############
const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setUserProfile: (state, action) => {
            let user = action.payload.data.user;
            let token = action.payload.data.token;
            state.user = user;
            state.authToken = token;
            state.isAuthenticated = true;
        },
        removeUserProfile: (state) => {
            state.user = [];
            state.authToken = '';
            state.isAuthenticated = false;
        },
    },
});

export const { setUserProfile, removeUserProfile } = authSlice.actions;
export default authSlice.reducer;
