import { baseURL } from './baseUrl/baseURL';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { orderEndponts } from './endpoints/ordersEndpoints';
import { headers } from './baseUrl/setHeaders';

// !############## API ################
export const orderAPI = createApi({
    reducerPath: 'orderAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Orders'],
    endpoints: (builder) => ({
        // => GET all orders
        getAllOrders: builder.query({
            query: (payload) =>
                `${orderEndponts.GET_ALL_ORDERS}/` + payload + `/orders`,
            providesTags: ['Orders'],
        }),

        // => GET order by id
        getOrderById: builder.query({
            query: (payload) => `${orderEndponts.GET_SINGLE_ORDER}/` + payload,
        }),

        // => DELIVER order
        deliverOrder: builder.mutation({
            query: (payload) => ({
                url: `${orderEndponts.DELIVER_ORDER}/` + payload.order_id,
                method: `PUT`,
                body: payload,
            }),
            invalidatesTags: ['Orders'],
        }),

        // => GET delivery pin
        getDeliveryPin: builder.query({
            query: (payload) => `${orderEndponts.GET_DELIVERY_PIN}/` + payload,
        }),
    }),
});

export const {
    useGetAllOrdersQuery,
    useGetOrderByIdQuery,
    useDeliverOrderMutation,
    useGetDeliveryPinQuery,
} = orderAPI;
