import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

// ############# CARD ###############
const Card = styled(Box)(({ theme }) => ({
    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
    borderRadius: 10,
    overflow: "hidden",
    position: "relative",
}));

// ############# CARD HEADER ###############
const CardHeader = styled(Box)(({ theme }) => ({
    background: colors.bgColor1,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 15,
    paddingRight: 10,
}));

// ############# CARD BODY ###############
const CardBody = styled(Box)(({ theme }) => ({}));

const CustomCardOne = ({
    height,
    bgColor,
    children,
    action,
    title,
    style,
    maxNoTitle,
}) => {
    return (
        <>
            <Card
                sx={{
                    m: 0,
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: `15px solid ${colors.bgColor1}`,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                }}
            >
                {/* ==== header ===== */}
                <CardHeader
                    sx={{
                        display: {
                            md: title ? "flex" : "none",
                            xs: maxNoTitle ? "none" : "block",
                        },
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: "bold",
                                opacity: 0.8,
                            }}
                            className="head-font"
                        >
                            {title}
                        </Typography>
                    </Box>
                    <Box>{action}</Box>
                </CardHeader>

                {/* ==== Body ===== */}
                <CardBody sx={{ flex: 1, overflow: "auto", position: 'relative' }}>
                    {children}
                </CardBody>
            </Card>
        </>
    );
};

export default CustomCardOne;
