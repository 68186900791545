import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import playStore from "../assets/media/images/playStore.png";
import { colors } from "../assets/utils/colors";
import deliveryAppAPK from "../assets/media/documents/delivery-app.apk";

const DeliveryAppBtn = () => {
    return (
        <>
            <a
                href={deliveryAppAPK}
                download
                target="_blank"
                rel="noreferrer"
                style={{ zIndex: 999 }}
            >
                <Box
                    sx={{
                        zIndex: 999,
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        p: 4,
                        position: "relative",
                        border: {
                            md: `10px solid ${colors.bgColor1}`,
                            xs: `5px solid ${colors.bgColor1}`,
                        },
                    }}
                    className="animated-bg"
                >
                    <Avatar
                        src={playStore}
                        variant="square"
                        sx={{
                            height: 70,
                            width: 70,
                            img: { objectFit: "contain" },
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            textAlign: "center",
                            mt: 2,
                            color: colors.primary,
                        }}
                    >
                        Get delivery app for android
                    </Typography>
                </Box>
            </a>
        </>
    );
};

export default DeliveryAppBtn;
