import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/forms/CustomTextField';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import { colors } from '../../../assets/utils/colors';
import * as Yup from 'yup';
import logo from '../../../assets/media/images/logo1.png';

// ################### Validation ###############
const orderNumberValicadtionSchema = Yup.object({
    order_id: Yup.string()
        .required('Please provide order number')
        .min(15, 'Order number  must be at least 15 characters')
        .max(30, 'Order number  must be at most 30 characters'),
});

const GetOrder = ({ getOrder, isError, orderNumber }) => {
    return (
        <>
            <Box
                sx={{
                    height: { md: 450, xs: 450 },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        'width': '100%',
                        'display': 'flex',
                        'justifyContent': 'center',
                        '& .logo': {
                            width: 160,
                            height: 66,
                        },
                    }}
                >
                    <Avatar src={logo} alt="Agiza logo" className="logo" />
                </Box>
                {isError ? (
                    <Box sx={{ my: 3 }}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.red,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            Order not found. Please make sure you provide
                            correct Order number.
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ my: 3 }}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.primary,
                                fontWeight: 'bold',
                            }}
                        >
                            Please provide Order number of the order you want to
                            deliver.
                        </Typography>
                    </Box>
                )}

                <Formik
                    enableReinitialize
                    initialValues={{
                        order_id: orderNumber,
                    }}
                    validationSchema={orderNumberValicadtionSchema}
                    onSubmit={(payload) => {
                        getOrder(payload);
                    }}
                >
                    {(formik) => (
                        <Box sx={{ width: '100%' }}>
                            <Form>
                                <Grid container justifyContent={'center'}>
                                    <Grid item sm={9} xs={12}>
                                        <CustomTextField
                                            type={'text'}
                                            name="order_id"
                                            label={'Provide order number'}
                                        />
                                    </Grid>
                                    <Grid item sm={9} sx={{ my: 4 }} xs={12}>
                                        <CustomSubmitButton
                                            onClick={formik.handleSubmit}
                                        >
                                            Get order
                                        </CustomSubmitButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default GetOrder;
