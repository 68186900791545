import { configureStore } from '@reduxjs/toolkit';
import { RTKmiddleware } from './RTKmiddlewares';

// => SLICE reduces
import authReducer from '../features/auth/authSlice';
import systemConfigReducer from '../features/systemConfig/systemConfigSlice';
import ordersReducer from '../features/orders/orderSlice';

// => RTK reducers
import { authAPI } from '../../api/authAPI';
import { orderAPI } from '../../api/ordersAPI';

export const store = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [orderAPI.reducerPath]: orderAPI.reducer,
        authReducer,
        systemConfigReducer,
        ordersReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
