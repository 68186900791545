// => GET all orders
const GET_ALL_ORDERS = '/delivery-guys';

// => GET single order
const GET_SINGLE_ORDER = '/orders';

// => Deliver order
const DELIVER_ORDER = '/delivery-guys/deliver-orders';

// => Get delivery pin
const GET_DELIVERY_PIN = '/orders/delivery-pins';

export const orderEndponts = {
    GET_ALL_ORDERS,
    GET_SINGLE_ORDER,
    DELIVER_ORDER,
    GET_DELIVERY_PIN,
};
