import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from './baseUrl/baseURL';
import { authEndpoints } from './endpoints/authEndpoints';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.LOGIN}`,
                method: 'POST',
                body: payload,
            }),
        }),
        logout: builder.mutation({
            query: (payload) => ({
                url: ``,
                method: ``,
                body: payload,
            }),
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation } = authAPI;
