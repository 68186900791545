import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import { Phone } from '@mui/icons-material';

const UserWidget1 = ({ primary, secondary, phone }) => {
    return (
        <>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    borderRadius: 2,
                    p: 0.5,
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <ListItem
                    sx={{
                        py: 0,
                    }}
                >
                    <ListItemAvatar>
                        <Avatar
                            src={''}
                            sx={{
                                height: 60,
                                width: 60,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                p: 0.5,
                                mr: 1,
                            }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={primary}
                        secondary={secondary}
                        primaryTypographyProps={{
                            fontSize: 14,
                        }}
                        secondaryTypographyProps={{
                            fontSize: 10,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            color: colors.primary,
                            fontStyle: 'italic',
                        }}
                    />
                </ListItem>
                <Box sx={{ position: 'absolute', right: 15 }}>
                    <a href={`tel:` + phone}>
                        <IconButton
                            sx={{
                                p: 0.8,
                                bgcolor: colors.bgColor1,
                                color: colors.primary,
                                ml: 1,
                            }}
                            size="small"
                        >
                            <Phone
                                sx={{
                                    fontSize: 16,
                                }}
                            />
                        </IconButton>
                    </a>
                </Box>
            </List>
        </>
    );
};

export default UserWidget1;
