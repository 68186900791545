import React, { useState } from 'react';
import useColor from '../hooks/useColor';
import { Box, Button, Typography } from '@mui/material';
import { LocalShippingTwoTone } from '@mui/icons-material';
import CustomDialog from './dialogs/CustomDialog';
import OrderDelivery from '../pages/orders/orderComp/OrderDelivery';

const QuickDeliveryBtn = () => {
    // ############### CONST ###############
    const colors = useColor();

    // ############## Comp State ###########
    const [openDialog, setOpenDialog] = useState(false);

    // ############# FUNCT #################
    // => Handle open dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // => Handle open dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            {/* ############### Dialog ############# */}
            <CustomDialog
                openDialog={openDialog}
                head={"Order delivery"}
                handleDialogClose={handleCloseDialog}
            >
                <OrderDelivery />
            </CustomDialog>
            <Button
                fullWidth
                variant="outlined"
                sx={{
                    p: { md: "default", xs: 0 },
                    borderRadius: { md: 5, xs: 5 },
                    textTransform: "capitalize",
                    border: {
                        md: `10px solid ${colors.bgColor1}`,
                        xs: `5px solid ${colors.bgColor1}`,
                    },
                    color: colors.primary,
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor3} !important`,
                    // background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                    ":hover": {
                        color: colors.primary,
                        "& .car1": {
                            color: colors.primary,
                            ml: 50,
                            transition: ".3s all ease-in-out",
                        },
                        "& .car2": {
                            color: "green",
                            ml: 0,
                            transition: ".3s all ease-in-out",
                        },
                        border: {
                            md: `10px solid ${colors.bgColor1}`,
                            xs: `5px solid ${colors.bgColor1}`,
                        },
                    },
                    "& .car1": {
                        // position: 'absolute',
                        transition: ".3s all ease-in-out",
                    },
                    "& .car2": {
                        ml: -50,
                        position: "absolute",
                        transition: ".3s all ease-in-out",
                    },
                }}
                onClick={() => {
                    handleOpenDialog();
                }}
                className="animated-bg"
            >
                <Box
                    sx={{
                        overflow: "hidden",
                        height: { md: 120, xs: 120 },
                        width: 135,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            position: "relative",
                        }}
                    >
                        <LocalShippingTwoTone
                            className="car1"
                            sx={{ fontSize: 60 }}
                        />
                        <LocalShippingTwoTone
                            className="car2"
                            sx={{ fontSize: 60 }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: { md: 14, xs: 13 },
                            }}
                        >
                            Quick delivery
                        </Typography>
                    </Box>
                </Box>
            </Button>
        </>
    );
};

export default QuickDeliveryBtn;
