// => Enviroment
const env = "pro";

// => Dev base url
const devBaseUrl = "http://192.168.0.9:7001/api/v2";

// => Pro base url
const proBaseUrl = "https://secure.agiza.africa/api/v2";

const getBaseURL = () => {
    if (env === "pro") {
        return proBaseUrl;
    }
    return devBaseUrl;
};

export const baseURL = getBaseURL();
