import React, { useState } from 'react';
import { LocalShippingTwoTone } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import CustomDialog from './dialogs/CustomDialog';
import OrderDelivery from '../pages/orders/orderComp/OrderDelivery';
import useColor from '../hooks/useColor';

const MobQuickDelivery = () => {
    // ############### CONST ###############
    const colors = useColor();

    // ############## Comp State ###########
    const [openDialog, setOpenDialog] = useState(false);

    // ############# FUNCT #################
    // => Handle open dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // => Handle open dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            {/* ############### Dialog ############# */}
            <CustomDialog
                openDialog={openDialog}
                head={'Order delivery'}
                handleDialogClose={handleCloseDialog}
            >
                <OrderDelivery />
            </CustomDialog>

            <Box
                sx={{
                    p: .5,
                    right: 10,
                    bottom: 70,
                    zIndex: 999,
                    position: 'fixed',
                    borderRadius: 2,
                    border: `1px solid ${colors.secondary}`,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                }}
            >
                <Tooltip title={'Deliver order'} arrow>
                    <Button
                        variant="contained"
                        className="app-btn-2"
                        sx={{
                            'overflow': 'hidden',
                            'width': 50,
                            ':hover': {
                                'color': colors.secondary,
                                '& .car1': {
                                    color: colors.primary,
                                    ml: 50,
                                    transition: '.3s all ease-in-out',
                                },
                                '& .car2': {
                                    color: 'green',
                                    ml: 0,
                                    transition: '.3s all ease-in-out',
                                },
                            },
                            '& .car1': {
                                // position: 'absolute',
                                transition: '.3s all ease-in-out',
                            },
                            '& .car2': {
                                ml: -50,
                                position: 'absolute',
                                transition: '.3s all ease-in-out',
                            },
                        }}
                        onClick={() => {
                            handleOpenDialog();
                        }}
                    >
                        <LocalShippingTwoTone
                            className="car1"
                            sx={{
                                fontSize: 25,
                            }}
                        />
                        <LocalShippingTwoTone
                            className="car2"
                            sx={{
                                fontSize: 25,
                            }}
                        />
                    </Button>
                </Tooltip>
            </Box>
        </>
    );
};

export default MobQuickDelivery;
