import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { Phone, Person2, Group } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { authSelector } from "../../state/features/selectors";

const MiniUserWidget = () => {
    // ############## Redux state ################
    const { user } = useSelector(authSelector);

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    borderRadius: 5,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        ".txt": {
                            fontSize: 10,
                            display: "flex",
                            alignItems: "center",
                            mb: 0.5,
                        },
                        ".icon": {
                            fontSize: 14,
                            opacity: 0.8,
                            mr: 1,
                            color: colors.primary,
                        },
                    }}
                >
                    <Avatar
                        sx={{
                            height: 70,
                            width: 70,
                            border: `5px solid ${colors.bgColor1}`,
                            borderRight: `5px solid ${colors.secondary}`,
                            borderLeft: `5px solid ${colors.secondary}`,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    />
                    <Box sx={{ ml: 1 }}>
                        <Typography className="txt">
                            <Person2 className="icon" />{" "}
                            {`${user.first_name} ${user.last_name}`}
                        </Typography>
                        <Typography className="txt">
                            <Group className="icon" /> {user.gender},
                        </Typography>
                        <Typography className="txt">
                            <Phone className="icon" /> {user.phone_number}
                        </Typography>
                    </Box>
                </Box>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 12,
                        color: colors.primary,
                        p: 2,
                    }}
                >
                    {user.registration_number}
                </Typography>
            </Box>
        </>
    );
};

export default MiniUserWidget;
