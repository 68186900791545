import { DocumentScanner } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import ShippingCoverPDF from "../../reports/ShippingCoverPDF";
import GenerateShippingCover from "../CRUD/GenerateShippingCover";
import { colors } from "../../../assets/utils/colors";
import ReportDialog from "../../../components/dialogs/ReportDialog";

const ShippingCover = ({ order }) => {
    // ############### Component state ###################
    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [deliveryData, setDeliveryData] = useState({});

    // => Handle dialog open
    const handleDialogOpen = () => {
        setReportDialogOpen(true);
    };
    // => Handle dialog close
    const handleDialogClose = () => {
        setReportDialogOpen(false);
    };
    // => handle delivery data
    const handleDeliveryData = (payload) => {
        setDeliveryData(payload);
    };

    return (
        <>
            <ReportDialog
                head={"Shipping cover"}
                customWidth={"md"}
                openDialog={reportDialogOpen}
                handleDialogClose={handleDialogClose}
                actionForm={
                    <GenerateShippingCover handler={handleDeliveryData} />
                }
            >
                <ShippingCoverPDF data={order} deliveryData={deliveryData} />
            </ReportDialog>

            <IconButton
                size="small"
                sx={{
                    px: 1,
                    py: 0,
                    height: 40,
                    ml: 3,
                    borderRadius: 2,
                    border: `3px dotted ${colors.bgColor2}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    transition: `.3s all ease-in-out`,
                    ":hover": {
                        transition: `.3s all ease-in-out`,
                        boxShadow: `0px 0px 5px ${colors.primary}`,
                    },
                }}
                onClick={handleDialogOpen}
            >
                <DocumentScanner color="primary" />
            </IconButton>
        </>
    );
};

export default ShippingCover;
