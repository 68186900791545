import React, { useState } from 'react';
import {
    Box,
    Button,
    Chip,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { LocalShippingTwoTone, MoreVert, PinDrop } from '@mui/icons-material';
import { currencyFomater } from '../../helpers/currencyFomater';
import moment from 'moment';
import CustomDialog from '../dialogs/CustomDialog';
import OrderDelivery from '../../pages/orders/orderComp/OrderDelivery';
import useColor from '../../hooks/useColor';
import { Link } from 'react-router-dom';

const OrderWidget2 = ({ order }) => {
    // ############### CONST ###############
    const colors = useColor();

    // ############## Comp State ###########
    const [openDialog, setOpenDialog] = useState(false);

    // ############# FUNCT #################
    // => Handle open dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // => Handle open dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            {/* ############### Dialog ############# */}
            <CustomDialog
                openDialog={openDialog}
                head={'Order delivery'}
                handleDialogClose={handleCloseDialog}
            >
                <OrderDelivery order={order} />
            </CustomDialog>

            <Box sx={{ p: 2 }}>
                <Box
                    sx={{
                        border: `2px solid ${colors.bgColor1}`,
                        borderRadius: 3,
                        overflow: 'hidden',
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    }}
                >
                    <Box
                        sx={{
                            p: 1.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Typography
                            sx={{ color: colors.primary, fontSize: 14 }}
                            noWrap
                        >
                            {order.order_number}
                        </Typography>
                        <Box>
                            <Chip
                                label={
                                    order.order_status === 3
                                        ? 'On-shipping'
                                        : 'Delivered'
                                }
                                size="small"
                                color={
                                    order.order_status === 3
                                        ? 'warning'
                                        : 'success'
                                }
                                sx={{ fontSize: { md: 11, xs: 10 } }}
                            />
                            <Link to={`/orders/details/${order._id}`}>
                                <IconButton
                                    size="small"
                                    color="info"
                                    sx={{
                                        mr: -1,
                                        ml: 1,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </Link>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ p: 1 }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Shipping address
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontStyle: 'italic',
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                }}
                            >
                                <PinDrop
                                    sx={{
                                        fontSize: 15,
                                        opacity: 0.8,
                                    }}
                                />
                                {order.shipping_address}
                            </Typography>
                        </Box>
                        <Box sx={{ p: 1, textAlign: 'center' }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Total amount
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    fontStyle: 'italic',
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                }}
                            >
                                TZS {currencyFomater(order.total_amount)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Box sx={{ p: 1, textAlign: 'center' }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Ordered
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    opacity: 0.8,
                                    color: 'green',
                                    fontWeight: 'bold',
                                }}
                            >
                                {moment(order.createdAt).format('ll')}
                            </Typography>
                        </Box>
                        <Box sx={{ p: 1, textAlign: 'center' }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Shipped
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    opacity: 0.8,
                                    color: 'red',
                                    fontWeight: 'bold',
                                }}
                            >
                                {moment(order.updatedAt).format('ll')}
                            </Typography>
                        </Box>
                        {order.order_status === 3 && (
                            <Box sx={{ p: 1, textAlign: 'center' }}>
                                <Tooltip title={'Deliver order'} arrow>
                                    <Button
                                        variant="contained"
                                        className="app-btn-2"
                                        sx={{
                                            'overflow': 'hidden',
                                            'width': 70,
                                            ':hover': {
                                                'color': colors.secondary,
                                                '& .car1': {
                                                    color: colors.primary,
                                                    ml: 50,
                                                    transition:
                                                        '.3s all ease-in-out',
                                                },
                                                '& .car2': {
                                                    color: 'green',
                                                    ml: 0,
                                                    transition:
                                                        '.3s all ease-in-out',
                                                },
                                            },
                                            '& .car1': {
                                                // position: 'absolute',
                                                transition:
                                                    '.3s all ease-in-out',
                                            },
                                            '& .car2': {
                                                ml: -50,
                                                position: 'absolute',
                                                transition:
                                                    '.3s all ease-in-out',
                                            },
                                        }}
                                        onClick={() => {
                                            handleOpenDialog();
                                        }}
                                    >
                                        <LocalShippingTwoTone />
                                    </Button>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default OrderWidget2;
