import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import OrderWidget3 from '../../../components/widgets/OrderWidget3';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/forms/CustomTextField';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import { colors } from '../../../assets/utils/colors';
import { SwapHoriz } from '@mui/icons-material';
import { useDeliverOrderMutation } from '../../../api/ordersAPI';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../state/features/selectors';
import * as Yup from 'yup';
import { errorNotify, successNotify } from '../../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { setOrders } from '../../../state/features/orders/orderSlice';
import SuccessFeedback from '../../../components/SuccessFeedback';
import { isEmpty } from 'lodash';

// ################### Validation ###############
const deliveryValicadtionSchema = Yup.object({
    delivery_pin: Yup.string().required('Please provide delivery PIN'),
    delivery_guy_id: Yup.string().required(),
});

const DeliverOrder = ({ order }) => {
    // ################ CONST ##################
    const dispatch = useDispatch();

    // ################ Redux state #############
    const { user } = useSelector(authSelector);

    // ################ RTK ###################
    const [
        deliverOrder,
        { isLoading, isSuccess, isError, error, data: deliveredOrder },
    ] = useDeliverOrderMutation();

    // ################# FUNC ################
    const handleOrderDeliver = (payload) => {
        deliverOrder(payload);
    };

    // ############### use Effect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setOrders(deliveredOrder));
                successNotify(getRTKSuccessMessage(deliveredOrder));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, deliveredOrder, error, dispatch]);

    return (
        <>
            {isSuccess ? (
                <>
                    <SuccessFeedback
                        message={
                            !isEmpty(deliveredOrder)
                                ? getRTKSuccessMessage(deliveredOrder)
                                : 'Order delivered successfully.'
                        }
                    />
                </>
            ) : (
                <>
                    <Grid item sm={12} xs={12}>
                        <Box>
                            <OrderWidget3 order={order} />
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: colors.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Please provide deliver PIN from customer to
                                deliver this order.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Box>
                            <Formik
                                initialValues={{
                                    delivery_pin: '',
                                    delivery_guy_id: user._id,
                                }}
                                validationSchema={deliveryValicadtionSchema}
                                onSubmit={(payload) => {
                                    let order_id = order._id;
                                    let data = {
                                        order_id,
                                        delivery_pin: payload.delivery_pin,
                                        delivery_guy_id:
                                            payload.delivery_guy_id,
                                    };
                                    handleOrderDeliver(data);
                                }}
                            >
                                {(formik) => (
                                    <Form>
                                        <Grid container>
                                            <Grid item sm={12} xs={12}>
                                                <CustomTextField
                                                    type={'text'}
                                                    name="delivery_pin"
                                                    label={
                                                        'Provide delivery pin'
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                sm={12}
                                                sx={{ my: 3 }}
                                                xs={12}
                                            >
                                                <CustomSubmitButton
                                                    loading={isLoading}
                                                    btnColor={'success'}
                                                    startIcon={<SwapHoriz />}
                                                    onClick={
                                                        formik.handleSubmit
                                                    }
                                                >
                                                    Deliver order
                                                </CustomSubmitButton>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Grid>
                </>
            )}
        </>
    );
};

export default DeliverOrder;
