import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { colors } from "../../assets/utils/colors";
import {  Chip, IconButton, Typography } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { currencyFomater } from "../../helpers/currencyFomater";

const columns = [
    { id: "sn", label: "S/N", align: "center" },
    { id: "order_id", label: "OrderID" },
    // { id: 'store', label: 'Store', minWidth: 200 },
    {
        id: "address",
        label: "Shipping-address",
    },
    {
        id: "status",
        label: "Status",
        align: "left",
    },
    {
        id: "price",
        label: (
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                Price
                <sup>TZS</sup>
            </Typography>
        ),
    },
    {
        id: "action",
        label: "",
        align: "left",
    },
];

function createData(sn, order_id, address, price, status, action) {
    return { sn, order_id, address, price, status, action };
}

const orderRow = (order, index) =>
    createData(
        <Typography
            className="head-font"
            noWrap
            sx={{ fontWeight: "bold", fontSize: 12 }}
        >
            {++index}
        </Typography>,
        <Typography
            className="head-font"
            noWrap
            sx={{ fontWeight: "bold", fontSize: 12, opacity: 0.8 }}
        >
            {order.order_number}
        </Typography>,
        // <Typography
        //     sx={{
        //         fontSize: 12,
        //         fontWeight: 'bold',
        //         color: colors.primary,
        //         display: 'flex',
        //         alignItems: 'center',
        //         background: `linear-gradient(270deg, ${colors.bgColor3}, ${colors.bgColor1})`,
        //     }}
        // >
        //     <Inventory sx={{ fontSize: 12, mr: 1 }} />
        //     {order.store_id.title}
        // </Typography>,
        <Typography sx={{ fontSize: 13 }}>{order.shipping_address}</Typography>,
        <Typography
            sx={{ fontSize: 14, fontWeight: "bold", color: colors.primary }}
        >
            {currencyFomater(order.total_amount)}
        </Typography>,
        <Chip
            label={
                order.order_status === 1
                    ? "Placed"
                    : order.order_status === 2
                    ? "Accepted"
                    : order.order_status === 3
                    ? "Shipped"
                    : order.order_status === 4
                    ? "Delivered"
                    : order.order_status === 5
                    ? "Cancelled"
                    : "ForShip"
            }
            color={
                order.order_status === 1
                    ? "primary"
                    : order.order_status === 2
                    ? "info"
                    : order.order_status === 3
                    ? "warning"
                    : order.order_status === 4
                    ? "success"
                    : order.order_status === 5
                    ? "error"
                    : "secondary"
            }
            size="small"
            sx={{ fontSize: 10, fontWeight: "bold" }}
        />,
        <Link to={`/orders/details/${order._id}`}>
            <IconButton
                sx={{
                    p: 0.8,
                    bgcolor: colors.bgColor1,
                    color: colors.primary,
                    ml: 1,
                }}
                size="small"
            >
                <RemoveRedEye
                    sx={{
                        fontSize: 16,
                    }}
                />
            </IconButton>
        </Link>
    );

// !################ MAIN FUNC ######################
const MainOrderTable = ({ orders, height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // ############# oreders ###############
    const storeOrders = orders.map((order, index) => orderRow(order, index));

    return (
        <>
            <Table stickyHeader>
                <TableHead
                    sx={{
                        position: "sticky",
                        top: 0,
                        opacity: 1,
                        zIndex: 999,
                    }}
                >
                    <TableRow sx={{ bgcolor: colors.bgColor1 }}>
                        {columns.map((column, index) => (
                            <TableCell
                                key={index}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    bgcolor: colors.bgColor1,
                                    opacity: 0.8,
                                }}
                                className="head-font"
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        flex: 1,
                        overflowY: "auto",
                    }}
                >
                    {storeOrders
                        .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {columns.map((column, index) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={index}
                                                align={column.align}
                                            >
                                                {column.format &&
                                                typeof value === "number"
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                page={page}
                component="div"
                count={orders.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 100]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    position: "sticky",
                    bottom: 0,
                    bgcolor: colors.bgColor1,
                }}
            />
        </>
    );
};

export default MainOrderTable;
