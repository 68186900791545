import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import bg1 from "../../assets/media/images/bg1.png";
import moment from "moment/moment";
import { DoneAll, PendingActions } from "@mui/icons-material";

// ################ OrderWidget ###############
const OrderWidget = styled(Box)(({ theme }) => ({
    borderRight: `1px solid ${colors.primary}`,
    paddingRight: 20,
    position: "relative",
    [theme.breakpoints.down("md")]: {
        borderRight: `none`,
        paddingRight: 0,
    },
}));

const DashOrderOverviewWidget = ({ bdColor, title, total, type }) => {
    return (
        <>
            <OrderWidget>
                <Box
                    sx={{
                        backgroundImage: `url(${bg1})`,
                        borderRadius: 2,
                    }}
                    className="animated-bg"
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            py: 2,
                            borderRadius: 2,
                            background: `rgba(125, 98, 212, .8);`,
                        }}
                    >
                        <Box
                            sx={{
                                height: 100,
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    bgcolor: bdColor,
                                    height: 110,
                                    width: 0,
                                    borderRight: `10px solid ${bdColor}`,
                                    borderTopRightRadius: 15,
                                    borderBottomRightRadius: 15,
                                    position: "absolute",
                                    left: 0,
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    right: 10,
                                    top: 10,
                                    p: 1,
                                    borderRadius: 50,
                                    height: 50,
                                    width: 50,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    opacity: 0.6,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                }}
                            >
                                {type === "completed" ? (
                                    <DoneAll
                                        sx={{ fontSize: 40 }}
                                        color="success"
                                    />
                                ) : (
                                    <PendingActions
                                        sx={{ fontSize: 40 }}
                                        color="warning"
                                    />
                                )}
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        color: colors.bgColor1,
                                        mt: 0.8,
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        color: colors.bgColor1,
                                        mt: 0.8,
                                    }}
                                >
                                    {total} Orders
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 11,
                                        color: colors.bgColor1,
                                        mt: 0.8,
                                        fontStyle: "italic",
                                    }}
                                >
                                    As of{" "}
                                    {moment().format("MMMM Do YYYY, h:mm:ss a")}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </OrderWidget>
        </>
    );
};

export default DashOrderOverviewWidget;
