import React from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from '../../components/forms/CustomTextField';
import * as Yup from 'yup';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';

// ############# validation ################
const changePassValidationSchema = Yup.object({
    old_password: Yup.string().required('old password is a required field'),
    new_password: Yup.string().required('new password is a required field'),
    confirm_password: Yup.string().required(
        'confirm password is a required field',
    ),
});
const ChangePassword = () => {
    return (
        <>
            <CustomCard2
                title={'Password management'}
                height={{ md: 227, xs: '100%' }}
            >
                <Box>
                    <Formik
                        initialValues={{
                            old_password: '',
                            new_password: '',
                            confirm_password: '',
                        }}
                        validationSchema={changePassValidationSchema}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    padding={2}
                                    spacing={2}
                                    justifyContent={'center'}
                                >
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            name="new_password"
                                            label={'New password'}
                                            type={'password'}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            name="confirm_password"
                                            label={'Password confimation'}
                                            type={'password'}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            name="old_password"
                                            label={'Old password'}
                                            type={'password'}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Box
                                            sx={{
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                pt: 2,
                                            }}
                                        >
                                            <CustomSubmitButton
                                                onClick={formik.handleSubmit}
                                            >
                                                Change password
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </CustomCard2>
        </>
    );
};

export default ChangePassword;
