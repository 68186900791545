import React, { useEffect, useState } from "react";
import { LocalShipping, LocationOn } from "@mui/icons-material";
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { useParams } from "react-router-dom";
import { useGetOrderByIdQuery } from "../../api/ordersAPI";
import moment from "moment";
import { currencyFomater } from "../../helpers/currencyFomater";
import { isEmpty, size } from "lodash";
import CustomCard2 from "../../components/cards/CustomCard2";
import InnerContentLoader from "../../components/InnerContentLoader";
import InnerDataFetchingError from "../../components/InnerDataFetchingError";
import { getRTKResponseData } from "../../helpers/RTKHelpers";
import UserWidget1 from "../../components/widgets/UserWidget1";
import NoContent from "../../components/NoContent";
import { setOrderDetails } from "../../state/features/orders/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { ordersSelector } from "../../state/features/selectors";
import Dashsidebar from "../../components/sidebars/Dashsidebar";
import PageLayout from "../layouts/PageLayout";
import OrderedProducts from "./orderComp/OrderedProducts";
import CustomDialog from "../../components/dialogs/CustomDialog";
import OrderDelivery from "./orderComp/OrderDelivery";
import ShippingCover from "./orderComp/ShippingCover";
import OrderQrcode from "../../components/qrCode/OrderQrcode";

// ############### MUI Styled ###################
const OrderContainer = styled(Box)(({ theme }) => ({
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    borderRadius: 5,
    display: "flex",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    flexDirection: "column",
    "& .order-header": {
        height: 50,
        background: colors.bgColor1,
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 15,
        paddingRight: 20,
        "& .head-font": {
            fontWeight: "bold",
        },
    },
}));

// !############## MAIN FUNC ################
const OrderDetails = () => {
    // ############# Component state #######
    const { orderDetails: order } = useSelector(ordersSelector);
    const [openDialog, setOpenDialog] = useState(false);

    // ############# CONST ##############
    const { order_id } = useParams();
    const dispatch = useDispatch();

    // ###### Distructure orders data #########
    const { customer } = order;
    console.log(order);
    const { ordered_products: products } = order;
    const { total_amount: amount } = order;
    const { order_status: status } = order;
    const { shipping_address: shippingAddress } = order;
    const { order_number: orderID } = order;
    const { store_id: store } = order;
    const { createdAt } = order;
    const vendor = store?.vendor_id;

    // ############# RTK ###############
    const {
        isLoading,
        isSuccess,
        isError,
        data: fetchedData,
        refetch,
    } = useGetOrderByIdQuery(order_id);

    // ############# FUNCT #################
    // => Handle open dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // => Handle open dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // ############ UseEffect #############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setOrderDetails(getRTKResponseData(fetchedData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, fetchedData, dispatch]);

    return (
        <>
            {/* ############### Dialog ############# */}
            <CustomDialog
                openDialog={openDialog}
                head={"Order delivery"}
                handleDialogClose={handleCloseDialog}
            >
                <OrderDelivery order={order} />
            </CustomDialog>

            <PageLayout
                pageHead={"Order details"}
                sidebar={
                    <Box
                        sx={{
                            display: {
                                md: "block",
                                xs: "none",
                                height: "100%",
                            },
                        }}
                    >
                        <Dashsidebar />
                    </Box>
                }
            >
                {isError ? (
                    <>
                        <InnerDataFetchingError
                            height={500}
                            content={"order "}
                            handleDataRefetch={refetch}
                        />
                    </>
                ) : (
                    <>
                        {isLoading ? (
                            <>
                                <CustomCard2 height={{ md: 650, xs: "67vh" }}>
                                    <InnerContentLoader height={"100%"} />
                                </CustomCard2>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        flex: 1,
                                        // overflow: "auto",
                                        position: "relative",
                                    }}
                                >
                                    <OrderQrcode orderNo={orderID} />
                                    <OrderContainer>
                                        {/* ######### Header ############ */}
                                        <Box className="order-header">
                                            <Typography
                                                className="head-font"
                                                sx={{ opacity: 0.7 }}
                                            >
                                                {orderID}
                                            </Typography>
                                            <Typography
                                                className="head-font"
                                                sx={{ opacity: 0.7 }}
                                            >
                                                {moment(createdAt).format("ll")}
                                            </Typography>
                                            {order.order_status === 3 && (
                                                <ShippingCover order={order} />
                                            )}
                                        </Box>
                                        {/* ########## Content ############ */}
                                        <Box
                                            className="order-content"
                                            sx={{
                                                flex: 1,
                                                overflow: "auto",
                                            }}
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                                padding={1}
                                            >
                                                {/* ############ Ordered products ############ */}
                                                <Grid item sm={7} xs={12}>
                                                    <OrderedProducts
                                                        products={products}
                                                    />
                                                </Grid>

                                                {/* ############ Ordered details ############ */}
                                                <Grid item sm={5} xs={12}>
                                                    <Box
                                                        sx={{
                                                            borderBottom: `2px solid ${colors.bgColor1}`,
                                                            pb: 1,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            Order summary
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            mt: 2,
                                                            bgcolor:
                                                                colors.bgColor1,
                                                        }}
                                                    >
                                                        {/* ################### status #################### */}
                                                        <Box
                                                            sx={{
                                                                height: 40,
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                display: "flex",
                                                                borderRadius: 1,
                                                                bgcolor:
                                                                    status === 1
                                                                        ? "primary"
                                                                        : status ===
                                                                          2
                                                                        ? colors.blue
                                                                        : status ===
                                                                          3
                                                                        ? colors.secondary
                                                                        : status ===
                                                                          4
                                                                        ? colors.green
                                                                        : status ===
                                                                          5
                                                                        ? colors.red
                                                                        : "secondary",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color:
                                                                        status ===
                                                                        1
                                                                            ? "primary"
                                                                            : status ===
                                                                              2
                                                                            ? colors.bgColor3
                                                                            : status ===
                                                                              3
                                                                            ? ""
                                                                            : status ===
                                                                              4
                                                                            ? ""
                                                                            : status ===
                                                                              5
                                                                            ? colors.bgColor3
                                                                            : "secondary",
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                {status === 1
                                                                    ? "Placed"
                                                                    : status ===
                                                                      2
                                                                    ? "Accepted"
                                                                    : status ===
                                                                      3
                                                                    ? "Shipped"
                                                                    : status ===
                                                                      4
                                                                    ? "Delivered"
                                                                    : status ===
                                                                      5
                                                                    ? "Cancelled"
                                                                    : "ForShip"}
                                                            </Typography>
                                                        </Box>

                                                        {/* ################### shipping #################### */}
                                                        <Box
                                                            sx={{
                                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                                                p: 2,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                    fontWeight:
                                                                        "bold",
                                                                    color: colors.primary,
                                                                }}
                                                            >
                                                                Shipping address
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <LocationOn
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        color: colors.primary,
                                                                        mr: 0.5,
                                                                    }}
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 10,
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        shippingAddress
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        {/* ################### customer #################### */}
                                                        <Box
                                                            sx={{
                                                                p: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    pb: 0.5,
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                Customer
                                                            </Typography>
                                                            <UserWidget1
                                                                primary={
                                                                    !isEmpty(
                                                                        customer
                                                                    ) &&
                                                                    `${customer.first_name} ${customer.last_name}`
                                                                }
                                                                secondary={
                                                                    !isEmpty(
                                                                        customer
                                                                    ) &&
                                                                    `${customer.city}, ${customer.country}`
                                                                }
                                                                phone={
                                                                    !isEmpty(
                                                                        customer
                                                                    ) &&
                                                                    `${customer.phone_number}`
                                                                }
                                                            />
                                                        </Box>

                                                        {/* ################### Delivery guy #################### */}
                                                        <Box
                                                            sx={{
                                                                p: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    pb: 0.5,
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                Vendor
                                                            </Typography>
                                                            {vendor ? (
                                                                <>
                                                                    <UserWidget1
                                                                        primary={
                                                                            !isEmpty(
                                                                                vendor
                                                                            ) &&
                                                                            `${vendor.first_name} ${vendor.last_name}`
                                                                        }
                                                                        secondary={
                                                                            !isEmpty(
                                                                                vendor
                                                                            ) &&
                                                                            `${vendor.city}`
                                                                        }
                                                                        phone={
                                                                            !isEmpty(
                                                                                vendor
                                                                            ) &&
                                                                            `${vendor.phone_number}`
                                                                        }
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <NoContent
                                                                        height={
                                                                            30
                                                                        }
                                                                        message={
                                                                            "No vendor"
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </Box>

                                                        {/* ################### Total items and action links #################### */}
                                                        <Box
                                                            sx={{
                                                                px: 1.5,
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "space-around",
                                                                mt: 2,
                                                            }}
                                                        >
                                                            {/* ################### Amount #################### */}
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-between",
                                                                    borderBottom: `2px solid ${colors.primary}`,
                                                                    py: 1,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Total items
                                                                </Typography>
                                                                <Typography>
                                                                    {size(
                                                                        products
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-between",
                                                                    borderBottom: `2px solid ${colors.primary}`,
                                                                    py: 1,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Total Price
                                                                </Typography>
                                                                <Typography
                                                                    noWrap
                                                                    className="head-font"
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        fontWeight:
                                                                            "bold",
                                                                        color: colors.primary,
                                                                    }}
                                                                >
                                                                    {currencyFomater(
                                                                        !isEmpty(
                                                                            amount
                                                                        ) &&
                                                                            amount
                                                                    )}
                                                                    <sup
                                                                        style={{
                                                                            fontSize: 9,
                                                                            color: colors.primary,
                                                                        }}
                                                                    >
                                                                        TZS
                                                                    </sup>
                                                                </Typography>
                                                            </Box>

                                                            {/* ################### Action buttons #################### */}
                                                            {status === 3 && (
                                                                <Box
                                                                    sx={{
                                                                        py: {
                                                                            md: 2,
                                                                            xs: 6,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className="app-btn"
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="success"
                                                                        fullWidth
                                                                        startIcon={
                                                                            <LocalShipping />
                                                                        }
                                                                        sx={{
                                                                            boxShadow:
                                                                                "none",
                                                                            mb: 1,
                                                                            mr: 1,
                                                                            fontSize: 12,
                                                                            textTransform:
                                                                                "capitalize",
                                                                            fontWeight:
                                                                                "bold",
                                                                        }}
                                                                        onClick={() => {
                                                                            handleOpenDialog();
                                                                        }}
                                                                    >
                                                                        Deliver
                                                                        order
                                                                    </Button>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </OrderContainer>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default OrderDetails;
