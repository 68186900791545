import { ArrowBack } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../assets/utils/colors";

const InnerPageTopbar = ({ title }) => {
    const navigate = useNavigate();
    return (
        <>
            <Grid
                container
                sx={{
                    zIndex: 999,
                }}
            >
                <Grid item xs={12} sm={12}>
                    <Box
                        sx={{
                            height: 50,
                            borderBottomRightRadius: 15,
                            borderBottomLeftRadius: 15,
                            display: "flex",
                            // px: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                        }}
                    >
                        <Typography
                            className="head-font section-head"
                            sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.primary,
                                position: "relative",
                            }}
                        >
                            {title}
                        </Typography>
                        <Tooltip title="Go back">
                            <IconButton
                                sx={{
                                    p: 0.8,
                                    bgcolor: colors.bgColor1,
                                    color: colors.primary,
                                    ml: 1,
                                    border: `1px dotted ${colors.primary}`,
                                }}
                                size="small"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBack
                                    sx={{
                                        fontSize: 20,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default InnerPageTopbar;
