import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { useSelector } from "react-redux";
import { ordersSelector } from "../../state/features/selectors";
import { round, size } from "lodash";

const OverallStatsWidget = () => {
    // ############## Redux state #############
    const { orders, runningOrders, completedOrders } =
        useSelector(ordersSelector);

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            height: 89,
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Box
                            sx={{
                                mt: 1,
                                borderBottom: `2px solid ${colors.primary}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    opacity: 0.8,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                }}
                            >
                                Received
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mt: 1,
                                    color: colors.primary,
                                    opacity: 0.6,
                                    fontWeight: "bold",
                                }}
                            >
                                {size(orders)}
                                <sup style={{ fontSize: 9 }}>100%</sup>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mt: 1,
                                borderBottom: `2px solid ${colors.secondary}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    opacity: 0.8,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                }}
                            >
                                Running
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mt: 1,
                                    color: colors.primary,
                                    opacity: 0.6,
                                    fontWeight: "bold",
                                }}
                            >
                                {size(runningOrders)}
                                <sup style={{ fontSize: 9 }}>
                                    {round(
                                        (size(runningOrders) * 100) /
                                            size(orders)
                                    )}
                                    %
                                </sup>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mt: 1,
                                borderBottom: `2px solid ${colors.green}`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    opacity: 0.8,
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                }}
                            >
                                Completed
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mt: 1,
                                    color: colors.primary,
                                    opacity: 0.6,
                                    fontWeight: "bold",
                                }}
                            >
                                {size(completedOrders)}
                                <sup style={{ fontSize: 9 }}>
                                    {round(
                                        (size(completedOrders) * 100) /
                                            size(orders)
                                    )}
                                    %
                                </sup>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default OverallStatsWidget;
