import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import AppLayout from '../pages/layouts/AppLayout';
import GuestRoutes from './GuestRoutes';
import PrivateRoutes from './PrivateRoutes';
import Welcome from '../pages/Welcome';
import RuniningOrders from '../pages/orders/RuniningOrders';
import CompletedOrders from '../pages/orders/CompletedOrders';
import Profile from '../pages/auth/Profile';
import OrderDetails from '../pages/orders/OrderDetails';

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => <Welcome />;

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);
const RunningOrdersElement = () => (
    <AppLayout>
        <RuniningOrders />
    </AppLayout>
);
const CompletedOrdersElement = () => (
    <AppLayout>
        <CompletedOrders />
    </AppLayout>
);
const OrderDetailsElement = () => (
    <AppLayout>
        <OrderDetails />
    </AppLayout>
);
const ProfileElement = () => (
    <AppLayout>
        <Profile />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ################## GUEST ROUTES ################## */}
                <Route element={<GuestRoutes />}>
                    <Route path="/" element={<LoginElement />} />
                </Route>

                {/* ################## PROTECTED ROUTES ################## */}
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<DashboardElement />} />
                    <Route path="/profile" element={<ProfileElement />} />
                    <Route
                        path="/orders/details/:order_id"
                        element={<OrderDetailsElement />}
                    />
                    <Route
                        path="/orders/running"
                        element={<RunningOrdersElement />}
                    />
                    <Route
                        path="/orders/completed"
                        element={<CompletedOrdersElement />}
                    />
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default App;
