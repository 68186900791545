import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Badge, Paper, Typography } from '@mui/material';
import { DoneAll, PendingActions, Window } from '@mui/icons-material';
import useColor from '../hooks/useColor';
import { useNavigate } from 'react-router-dom';
import { size } from 'lodash';
import { useSelector } from 'react-redux';
import { ordersSelector } from '../state/features/selectors';

const BottomBar = () => {
    // ############## CONST ################
    const colors = useColor();
    const navigate = useNavigate();

    // ############## Comp state ################
    const [value, setValue] = React.useState(0);

    // ############## Redux state ################
    const { runningOrders, completedOrders } = useSelector(ordersSelector);

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
            }}
            elevation={3}
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                }}
            >
                <BottomNavigationAction
                    label="Home"
                    icon={<Window />}
                    onClick={() => navigate('/dashboard')}
                />
                <BottomNavigationAction
                    label={
                        <Typography
                            sx={{
                                fontSize: 12,
                            }}
                        >
                            Running
                        </Typography>
                    }
                    icon={
                        <Badge
                            showZero
                            badgeContent={size(runningOrders)}
                            color="warning"
                        >
                            <PendingActions />
                        </Badge>
                    }
                    onClick={() => {
                        navigate('/orders/running');
                    }}
                />
                <BottomNavigationAction
                    label={
                        <Typography
                            sx={{
                                fontSize: 12,
                            }}
                        >
                            Completed
                        </Typography>
                    }
                    icon={
                        <Badge
                            showZero
                            badgeContent={size(completedOrders)}
                            color="success"
                        >
                            <DoneAll />
                        </Badge>
                    }
                    onClick={() => {
                        navigate('/orders/completed');
                    }}
                />
            </BottomNavigation>
        </Paper>
    );
};

export default BottomBar;
