import React from "react";
import { Box, Grid } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import CustomCardOne from "../../components/cards/CustomCardOne";
import Dashsidebar from "../../components/sidebars/Dashsidebar";
import { useSelector } from "react-redux";
import { ordersSelector } from "../../state/features/selectors";
import NoContent from "../../components/NoContent";
import { isEmpty } from "lodash";
import MainOrderTable from "../../components/tables/MainOrderTable";
import MainOrderTable2 from "../../components/tables/MainOrderTable2";

const RuniningOrders = () => {
    // ############## Redux state ################
    const { completedOrders: orders } = useSelector(ordersSelector);

    return (
        <>
            <PageLayout
                sidebar={
                    <Box sx={{ display: { md: "block", xs: "none", height: '100%' } }}>
                        <Dashsidebar />
                    </Box>
                }
                pageHead={"Completed orders"}
            >
                <Box
                    sx={{
                        flex: 1,
                        overflow: "auto",
                        position: "relative",
                    }}
                >
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            <CustomCardOne
                                maxNoTitle
                                title={"Delivered orders"}
                            >
                                <Grid container>
                                    <Grid item sm={12} xs={12}>
                                        {isEmpty(orders) ? (
                                            <>
                                                <NoContent
                                                    message={
                                                        "No completed order"
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: {
                                                            md: "block",
                                                            xs: "none",
                                                        },
                                                    }}
                                                >
                                                    <MainOrderTable
                                                        orders={orders}
                                                        height={538}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: {
                                                            md: "none",
                                                            xs: "block",
                                                        },
                                                    }}
                                                >
                                                    <MainOrderTable2
                                                        orders={orders}
                                                        height={"67vh"}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </CustomCardOne>
                        </Grid>
                    </Grid>
                </Box>
            </PageLayout>
        </>
    );
};

export default RuniningOrders;
