import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { NightsStay, WbSunny } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { systemConfigSelector } from "../state/features/selectors";
import { setDarkMode } from "../state/features/systemConfig/systemConfigSlice";
import useColor from "../hooks/useColor";

const DarkModeToggle = ({ desktopOpen }) => {
    // ######### CONST ####################
    const dispatch = useDispatch();
    const colors = useColor();

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    // ############ FUNC ############
    const handleDarkModeToggle = (payload) => {
        dispatch(setDarkMode(payload));
    };
    return (
        <>
            <Box
                sx={{
                    bgcolor: colors.bgColor2,
                    display: "flex",
                    flexDirection: desktopOpen ? "row" : "column",
                    p: 0.7,
                    borderRadius: 2,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    startIcon={<WbSunny sx={{ mr: 0.5 }} />}
                    sx={{
                        height: 35,
                        mr: 1,
                        width: "50%",
                        bgcolor: darkMode ? colors.bgColor1 : colors.primary,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 2,
                        color: darkMode ? colors.primary : colors.bgColor3,
                        ":hover": {
                            bgcolor: darkMode
                                ? colors.bgColor1
                                : colors.primary,
                        },
                    }}
                    onClick={() => {
                        handleDarkModeToggle(false);
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            display: desktopOpen ? "block" : "none",
                        }}
                    >
                        Light
                    </Typography>
                </Button>
                <Button
                    startIcon={
                        <NightsStay
                            sx={{
                                mr: 0.5,
                                color: darkMode
                                    ? colors.bgColor3
                                    : colors.primary,
                            }}
                        />
                    }
                    sx={{
                        height: 35,
                        width: "50%",
                        ml: 1,
                        bgcolor: darkMode ? colors.primary : colors.bgColor1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 2,
                        color: darkMode ? colors.bgColor3 : colors.primary,
                        ":hover": {
                            bgcolor: darkMode && colors.primary,
                        },
                    }}
                    onClick={() => {
                        handleDarkModeToggle(true);
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            display: desktopOpen ? "block" : "none",
                        }}
                    >
                        Dark
                    </Typography>
                </Button>
            </Box>
        </>
    );
};

export default DarkModeToggle;
