import React from 'react';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { LocationOn, MoreVert, Phone, PinDrop } from '@mui/icons-material';
import { currencyFomater } from '../../helpers/currencyFomater';
import moment from 'moment/moment';
import useColor from '../../hooks/useColor';
import { Link } from 'react-router-dom';

const OrderWidget3 = ({ order }) => {
    // ############### CONST ###############
    const colors = useColor();

    return (
        <>
            <Box sx={{ py: 2 }}>
                <Box
                    sx={{
                        border: `2px solid ${colors.bgColor1}`,
                        borderRadius: 3,
                        overflow: 'hidden',
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    }}
                >
                    <Box
                        sx={{
                            p: 1.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors.primary,
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                            noWrap
                        >
                            {order.order_number}
                        </Typography>
                        <Box>
                            <Chip
                                label={
                                    order.order_status === 3
                                        ? 'On-shipping'
                                        : 'Delivered'
                                }
                                size="small"
                                color={
                                    order.order_status === 3
                                        ? 'warning'
                                        : 'success'
                                }
                                sx={{ fontSize: { md: 11, xs: 10 } }}
                            />
                            <Link to={`/orders/details/${order._id}`}>
                                <IconButton
                                    size="small"
                                    color="info"
                                    sx={{
                                        mr: -1,
                                        ml: 1,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </Link>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            pr: 1,
                        }}
                    >
                        <Box sx={{ p: 1 }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                {order.store_id.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontStyle: 'italic',
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                }}
                            >
                                <LocationOn
                                    sx={{
                                        fontSize: 15,
                                        opacity: 0.8,
                                    }}
                                />
                                {order.store_id.location_name}
                            </Typography>
                        </Box>
                        <Box>
                            <Tooltip
                                title={order.store_id?.vendor_id?.phone_number}
                                arrow
                            >
                                <a
                                    href={
                                        `tel:` +
                                        order.store_id?.vendor_id?.phone_number
                                    }
                                >
                                    <IconButton
                                        sx={{
                                            p: 0.8,
                                            bgcolor: colors.bgColor1,
                                            color: colors.primary,
                                            ml: 1,
                                        }}
                                        size="small"
                                    >
                                        <Phone
                                            sx={{
                                                fontSize: 16,
                                            }}
                                        />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ p: 1 }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Shipping address
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontStyle: 'italic',
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                }}
                            >
                                <PinDrop
                                    sx={{
                                        fontSize: 15,
                                        opacity: 0.8,
                                    }}
                                />
                                {order.shipping_address}
                            </Typography>
                        </Box>
                        <Box sx={{ p: 1, textAlign: 'center' }}>
                            <Typography
                                noWrap
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Total amount
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    fontStyle: 'italic',
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                }}
                            >
                                TZS {currencyFomater(order.total_amount)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                        }}
                    >
                        <Box sx={{ p: 1, textAlign: 'center' }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Ordered
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    opacity: 0.8,
                                    color: 'green',
                                    fontWeight: 'bold',
                                }}
                            >
                                {moment(order.createdAt).format('ll')}
                            </Typography>
                        </Box>
                        <Box sx={{ p: 1, textAlign: 'center' }}>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Shipped
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    opacity: 0.8,
                                    color: 'red',
                                    fontWeight: 'bold',
                                }}
                            >
                                {moment(order.updatedAt).format('ll')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default OrderWidget3;
