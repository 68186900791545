import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../assets/utils/colors";

const CustomListItem = ({ desktopOpen, text, icon, url }) => {
    return (
        <React.Fragment>
            <NavLink to={url}>
                {({ isActive }) => (
                    <ListItem disablePadding>
                        <ListItemButton
                            sx={{
                                // borderRight: isActive
                                //     ? `4px solid ${colors.primary}`
                                //     : undefined,
                                height: 40,
                                mb: 1,
                                bgcolor: isActive
                                    ? colors.bgColor2
                                    : colors.bgColor3,
                                borderRadius: 2,
                                justifyContent: desktopOpen
                                    ? "initial"
                                    : "center",
                                px: 2.5,
                                // borderBottom: `1px solid ${colors.bgColor1}`,
                                "&:hover": {
                                    bgcolor: colors.bgColor2,
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: desktopOpen ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                {icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={text}
                                sx={{
                                    opacity: desktopOpen ? 1 : 0,
                                    "& .MuiTypography-root": {
                                        fontSize: 12,
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </NavLink>
        </React.Fragment>
    );
};

export default CustomListItem;
