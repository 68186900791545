import React from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import Dashsidebar from "../../components/sidebars/Dashsidebar";
import { useSelector } from "react-redux";
import { authSelector } from "../../state/features/selectors";
import useColor from "../../hooks/useColor";
import { ManageAccounts } from "@mui/icons-material";
import ChangePassword from "./ChangePassword";
import CustomCardOne from "../../components/cards/CustomCardOne";

const Profile = () => {
    // ############## Const ################
    const colors = useColor();

    // ############## Redux state ################
    const { user } = useSelector(authSelector);

    return (
        <>
            <PageLayout
                sidebar={
                    <Box sx={{ display: { md: "block", xs: "none", height: '100%' } }}>
                        <Dashsidebar />
                    </Box>
                }
                pageHead={"Profile management"}
            >
                <Box
                    sx={{
                        flex: 1,
                        overflow: "auto",
                        position: "relative",
                    }}
                >
                    <CustomCardOne maxNoTitle title={"All orders"}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        height: "100%",
                                        borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                                    }}
                                >
                                    <Grid container>
                                        <Grid item sm={12} xs={12}>
                                            <Box
                                                sx={{
                                                    p: 2,
                                                    textAlign: "center",
                                                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 20,
                                                        fontWeight: "bold",
                                                        color: colors.primary,
                                                    }}
                                                >
                                                    {user.registration_number}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <Box sx={{ pt: 2 }}>
                                                <Avatar
                                                    src={""}
                                                    variant="square"
                                                    sx={{
                                                        height: 240,
                                                        width: "100%",
                                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                        p: 0.5,
                                                        mr: 1,
                                                        borderRadius: 5,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={8} xs={12}>
                                            <Box sx={{ p: 2, width: "100%" }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 16,
                                                        fontWeight: "bold",
                                                        color: colors.primary,
                                                        position: "relative",
                                                        "&::before": {
                                                            content: "''",
                                                            height: 10,
                                                            width: "100%",
                                                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                                                            position:
                                                                "absolute",
                                                            bottom: -20,
                                                            borderRadius: 5,
                                                        },
                                                    }}
                                                >
                                                    Issac Adam
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <Grid
                                                    container
                                                    padding={2}
                                                    spacing={2}
                                                    justifyContent={"center"}
                                                    sx={{
                                                        "& .head": {
                                                            fontWeight: "bold",
                                                            opacity: 0.7,
                                                        },
                                                        "& .value": {
                                                            fontWeight: "bold",
                                                            opacity: 0.7,
                                                            fontSize: 14,
                                                            color: colors.primary,
                                                        },
                                                    }}
                                                >
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <Typography className="head">
                                                                First name
                                                            </Typography>
                                                            <Typography className="value">
                                                                {
                                                                    user.first_name
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <Typography className="head">
                                                                Last name
                                                            </Typography>
                                                            <Typography className="value">
                                                                {user.last_name}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <Typography className="head">
                                                                Phone number
                                                            </Typography>
                                                            <Typography className="value">
                                                                {
                                                                    user.phone_number
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={6} xs={6}>
                                                        <Box>
                                                            <Typography className="head">
                                                                Gender
                                                            </Typography>
                                                            <Typography className="value">
                                                                {user.gender}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sm={5}
                                                        sx={{ mt: 1 }}
                                                    >
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            className="app-btn-2"
                                                            startIcon={
                                                                <ManageAccounts />
                                                            }
                                                        >
                                                            Edit profile
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <ChangePassword />
                            </Grid>
                        </Grid>
                    </CustomCardOne>
                </Box>
            </PageLayout>
        </>
    );
};

export default Profile;
