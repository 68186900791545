import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AppRoutes from './routes/App.routes';
import useAuth from './hooks/useAuth';
import { useSelector } from 'react-redux';
import { authSelector } from './state/features/selectors';
import { isEmpty } from 'lodash';
import useTheme from './hooks/useTheme';

// !#################### MAIN FUNC ##################
const App = () => {
    // ######### CONST #############
    const theme = useTheme();

    // ######### Redux states #############
    const { user } = useSelector(authSelector);

    // ######### Get user profile #########
    const auth = useAuth();
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isEmpty(user)) {
                auth.persistAuthenticatedUser();
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [user, auth]);

    // ######### Main return #########
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppRoutes />
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
